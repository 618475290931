<template>
  <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
    <v-dialog
        :value="showDialog"
        :persistent="false"
        width="600"
        @input="closeDialog"
    >
      <v-card>
        <v-card-title class="headline">
          <span><i class="fa fa-pencil-square-o"></i> {{ currentItem ? 'Редактирование структурного подразделения' : 'Добавление структурного подразделения' }}</span>
        </v-card-title>

        <v-card-text>
          <form>
            <v-layout column>
              <ValidationProvider name="name" rules="required" v-slot="{invalid, validated}">
                <v-text-field
                    :error="invalid && validated"
                    label="Название СП *"
                    hide-details="auto"
                    v-model="name"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="email_for_notification" rules="required|email" v-slot="{invalid, validated}">
                <v-text-field
                    :error="invalid && validated"
                    label="Email для отправки уведомлений *"
                    hide-details="auto"
                    v-model="email_for_notification"
                ></v-text-field>
              </ValidationProvider>
            </v-layout>
          </form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
          <CButton :disabled="invalid" @click="handleSubmit(submit)" color="success">
            <i class="fa fa-save mr-2"></i>
            <span>Сохранить</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import EntityModalMixin from '@/views/grid_elements/mixins/EntityModalMixin';
import api from '@/api';

export default {
  name: "subdivision-modal",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  mixins: [EntityModalMixin],
  data() {
    let state = {
      name: '',
      email_for_notification: '',
    };

    if (this.currentItem) {
      state = {
        ...state,
        ...this.currentItem
      }
    }

    return state;
  },
  methods: {
    createEntity: api.structural_subdivisions.createSubdivision,
    updateEntity: api.structural_subdivisions.updateSubdivision,
  },
  computed: {
    data() {
      return {
        id: this.id,
        name: this.name,
        email_for_notification: this.email_for_notification
      }
    }
  }
}
</script>
