<template>
    <div>
        <v-row class="table-actions-row">
            <v-col sm="4" cols="12" class="pl-0">
                <breadcrumbs :items="crumbs" :exact="true"> </breadcrumbs>
            </v-col>
            <v-col sm="8" class="d-flex justify-end align-center">
                <CButton
                    @click="
                        clearCurrentItem()
                        showEntityModal = true
                    "
                    class="add-product-modal"
                    color="info"
                    ><i class="la la-plus"></i>
                    Добавить структурное подразделение
                </CButton>
            </v-col>
        </v-row>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    ref="grid"
                    class="structural-subdivision"
                    :sortable="true"
                    :filterable="true"
                    :pageable="gridPageable"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    detail="detail"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template
                        v-slot:detail="{
                            props: {
                                dataItem: { products },
                            },
                        }"
                    >
                        <div class="detail-wrapper">
                            <v-tabs v-model="tab">
                                <v-tab key="products" href="#products"
                                    >Товары</v-tab
                                >
                                <v-tabs-items v-model="tab">
                                    <v-tab-item key="products" id="products">
                                        <v-container
                                            class="ml-0 grey lighten-5"
                                        >
                                            <div
                                                v-if="
                                                    products && products.length
                                                "
                                            >
                                                <table role="presentation">
                                                    <thead role="presentation">
                                                        <tr
                                                            role="row"
                                                            aria-rowindex="1"
                                                        >
                                                            <th
                                                                aria-readonly="true"
                                                                aria-selected="false"
                                                                role="columnheader"
                                                                tabindex="-1"
                                                                class="k-header"
                                                                rowspan="1"
                                                                colspan="1"
                                                                aria-colindex="1"
                                                            >
                                                                Название товара
                                                                <span
                                                                    class="k-column-resizer"
                                                                    style="
                                                                        display: block;
                                                                        right: 0px;
                                                                    "
                                                                ></span>
                                                            </th>

                                                            <th
                                                                aria-readonly="true"
                                                                aria-selected="false"
                                                                role="columnheader"
                                                                tabindex="-1"
                                                                class="k-header"
                                                                rowspan="1"
                                                                colspan="1"
                                                                aria-colindex="2"
                                                                style=""
                                                            >
                                                                Баланс
                                                                <span
                                                                    class="k-column-resizer"
                                                                    style="
                                                                        display: block;
                                                                        right: 0px;
                                                                    "
                                                                ></span>
                                                            </th>

                                                            <th
                                                                aria-readonly="true"
                                                                aria-selected="false"
                                                                role="columnheader"
                                                                tabindex="-1"
                                                                class="k-header"
                                                                rowspan="1"
                                                                colspan="1"
                                                                aria-colindex="3"
                                                                style=""
                                                            >
                                                                Минимальное
                                                                количество для
                                                                заказа
                                                                <span
                                                                    class="k-column-resizer"
                                                                    style="
                                                                        display: block;
                                                                        right: 0px;
                                                                    "
                                                                ></span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                                <table
                                                    class="k-grid-table"
                                                    role="presentation"
                                                    style="
                                                        transform: translateY(
                                                            0px
                                                        );
                                                    "
                                                >
                                                    <tbody role="presentation">
                                                        <tr
                                                            v-for="(
                                                                product, key
                                                            ) in products"
                                                            class="k-alt"
                                                            data-kendo-grid-item-index="0"
                                                            role="row"
                                                            aria-rowindex="2"
                                                        >
                                                            <td
                                                                aria-selected="false"
                                                                tabindex="-1"
                                                                colspan="1"
                                                                aria-colindex="1"
                                                            >
                                                                {{
                                                                    product.product_name
                                                                }}
                                                            </td>
                                                            <td
                                                                aria-selected="false"
                                                                tabindex="-1"
                                                                colspan="1"
                                                                aria-colindex="2"
                                                            >
                                                                {{
                                                                    product.structural_subdivision_balance
                                                                }}
                                                            </td>
                                                            <td
                                                                aria-selected="false"
                                                                tabindex="-1"
                                                                colspan="1"
                                                                aria-colindex="3"
                                                            >
                                                                {{
                                                                    product.minimum_count_for_order
                                                                }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </v-container>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-tabs>
                        </div>
                    </template>
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <actions-dropdown
                                    :actions="[
                                        {
                                            text: 'Редактировать',
                                            icon: 'la la-pencil',
                                            handler: () => {
                                                setCurrentItem(dataItem)
                                                showEntityModal = true
                                            },
                                        },
                                        {
                                            text: 'Удалить',
                                            icon: 'la la-trash',
                                            handler: () =>
                                                deleteEntity(dataItem),
                                        },
                                    ]"
                                />
                            </div>
                        </td>
                    </template>
                </Grid>
            </intl>
        </localization>
        <subdivision-modal
            v-if="showEntityModal"
            :current-item="currentItem"
            :show-dialog="showEntityModal"
            @click:outside="showEntityModal = false"
            @stored="entityStored($event)"
            @close="showEntityModal = false"
        ></subdivision-modal>
    </div>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import { Grid } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl'
    import ActionsDropdown from '@/views/grid_elements/ActionsDropdown'
    import { showConfirm } from '@/lib/swal2'
    import { showMessage } from '@/lib/toasted'
    import Breadcrumbs from '@/views/components/breadcrumbs'
    import SubdivisionCreateUpdateModal from '../modals/SubdivisionCreateUpdateModal'
    import '@/views/grid_elements/translations'
    import api from '@/api'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'

    export default {
        name: 'subdivisions',
        components: {
            Grid: Grid,
            breadcrumbs: Breadcrumbs,
            'subdivision-modal': SubdivisionCreateUpdateModal,
            localization: LocalizationProvider,
            intl: IntlProvider,
            ActionsDropdown,
        },
        mixins: [TableMixin],
        mounted() {
            this.getData()
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                total: 7,
                expandedItems: [],
                gridPageable: { pageSizes: true },
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'name',
                        title: 'Название',
                    },
                    {
                        field: 'email_for_notification',
                        title: 'Email для отправки уведомлений',
                    },
                ],
                tab: null,
            }
        },
        methods: {
            fetchData: api.structural_subdivisions.getSubdivisions,
            async deleteEntity(item) {
                const { isConfirmed } = await showConfirm(
                    'Вы действительно хотите удалить данный элемент?'
                )

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.structural_subdivisions.deleteSubdivision(
                        item.id
                    )
                    showMessage(status, message)

                    await this.getData()
                }
            },
            async expandChange(event) {
                if (event.value) {
                    await this.getSubdivisionProducts(event.dataItem)
                }

                this.$set(
                    event.dataItem,
                    event.target.$props.expandField,
                    event.value
                )
            },
            async getSubdivisionProducts(dataItem) {
                const {
                    data: {
                        data: { items },
                    },
                } = await api.structural_subdivisions.subdivisionsProductsShort(
                    dataItem.id
                )

                dataItem.products = items
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
        },
    }
</script>

<style scoped></style>
