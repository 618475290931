var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"table-actions-row"},[_c('v-col',{staticClass:"pl-0",attrs:{"sm":"4","cols":"12"}},[_c('breadcrumbs',{attrs:{"items":_vm.crumbs,"exact":true}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"sm":"8"}},[_c('CButton',{staticClass:"add-product-modal",attrs:{"color":"info"},on:{"click":function($event){_vm.clearCurrentItem()
                    _vm.showEntityModal = true}}},[_c('i',{staticClass:"la la-plus"}),_vm._v(" Добавить структурное подразделение ")])],1)],1),_c('localization',{attrs:{"language":'ru-RU'}},[_c('intl',{attrs:{"locale":'ru'}},[_c('Grid',{ref:"grid",staticClass:"structural-subdivision",attrs:{"sortable":true,"filterable":true,"pageable":_vm.gridPageable,"filter":_vm.filter,"take":_vm.take,"skip":_vm.skip,"sort":_vm.sort,"page-size":_vm.pageSize,"total":_vm.total,"data-items":_vm.dataResult,"columns":_vm.columns,"expand-field":'expanded',"detail":"detail"},on:{"datastatechange":_vm.dataStateChange,"expandchange":_vm.expandChange,"sortchange":_vm.sortChange},scopedSlots:_vm._u([{key:"detail",fn:function(ref){
                    var products = ref.props.dataItem.products;
return [_c('div',{staticClass:"detail-wrapper"},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"products",attrs:{"href":"#products"}},[_vm._v("Товары")]),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"products",attrs:{"id":"products"}},[_c('v-container',{staticClass:"ml-0 grey lighten-5"},[(
                                                products && products.length
                                            )?_c('div',[_c('table',{attrs:{"role":"presentation"}},[_c('thead',{attrs:{"role":"presentation"}},[_c('tr',{attrs:{"role":"row","aria-rowindex":"1"}},[_c('th',{staticClass:"k-header",attrs:{"aria-readonly":"true","aria-selected":"false","role":"columnheader","tabindex":"-1","rowspan":"1","colspan":"1","aria-colindex":"1"}},[_vm._v(" Название товара "),_c('span',{staticClass:"k-column-resizer",staticStyle:{"display":"block","right":"0px"}})]),_c('th',{staticClass:"k-header",attrs:{"aria-readonly":"true","aria-selected":"false","role":"columnheader","tabindex":"-1","rowspan":"1","colspan":"1","aria-colindex":"2"}},[_vm._v(" Баланс "),_c('span',{staticClass:"k-column-resizer",staticStyle:{"display":"block","right":"0px"}})]),_c('th',{staticClass:"k-header",attrs:{"aria-readonly":"true","aria-selected":"false","role":"columnheader","tabindex":"-1","rowspan":"1","colspan":"1","aria-colindex":"3"}},[_vm._v(" Минимальное количество для заказа "),_c('span',{staticClass:"k-column-resizer",staticStyle:{"display":"block","right":"0px"}})])])])]),_c('table',{staticClass:"k-grid-table",staticStyle:{"transform":"translateY("},attrs:{"role":"presentation"}},[_c('tbody',{attrs:{"role":"presentation"}},_vm._l((products),function(product,key){return _c('tr',{staticClass:"k-alt",attrs:{"data-kendo-grid-item-index":"0","role":"row","aria-rowindex":"2"}},[_c('td',{attrs:{"aria-selected":"false","tabindex":"-1","colspan":"1","aria-colindex":"1"}},[_vm._v(" "+_vm._s(product.product_name)+" ")]),_c('td',{attrs:{"aria-selected":"false","tabindex":"-1","colspan":"1","aria-colindex":"2"}},[_vm._v(" "+_vm._s(product.structural_subdivision_balance)+" ")]),_c('td',{attrs:{"aria-selected":"false","tabindex":"-1","colspan":"1","aria-colindex":"3"}},[_vm._v(" "+_vm._s(product.minimum_count_for_order)+" ")])])}),0)])]):_vm._e()])],1)],1)],1)],1)]}},{key:"actions",fn:function(ref){
                                            var dataItem = ref.props.dataItem;
return [_c('td',[_c('div',{staticClass:"actionsColumn"},[_c('actions-dropdown',{attrs:{"actions":[
                                    {
                                        text: 'Редактировать',
                                        icon: 'la la-pencil',
                                        handler: function () {
                                            _vm.setCurrentItem(dataItem)
                                            _vm.showEntityModal = true
                                        },
                                    },
                                    {
                                        text: 'Удалить',
                                        icon: 'la la-trash',
                                        handler: function () { return _vm.deleteEntity(dataItem); },
                                    } ]}})],1)])]}}])})],1)],1),(_vm.showEntityModal)?_c('subdivision-modal',{attrs:{"current-item":_vm.currentItem,"show-dialog":_vm.showEntityModal},on:{"click:outside":function($event){_vm.showEntityModal = false},"stored":function($event){return _vm.entityStored($event)},"close":function($event){_vm.showEntityModal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }